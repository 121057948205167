import { type TableSortDirection, type CharlieTableRow } from '@atlassian/charlie-table';

export const NO_DATA_PLACEHOLDER = '—';

export type TableSorting<T> = {
	field: Extract<keyof T, string>;
	direction: TableSortDirection;
};

export const sortByState =
	<TableDataModel extends object>(sorting?: TableSorting<TableDataModel>) =>
	(rowA: CharlieTableRow<TableDataModel>, rowB: CharlieTableRow<TableDataModel>) => {
		if (!sorting) {
			return 0;
		}

		const valueA = rowA.data[sorting.field];
		const valueB = rowB.data[sorting.field];

		// Handle numerical values
		const isNumberA = typeof valueA === 'number';
		const isNumberB = typeof valueB === 'number';

		if (isNumberA && isNumberB) {
			return sorting.direction === 'asc' ? valueA - valueB : valueB - valueA;
		}

		// For ISO dates and other strings, fallback to string comparison
		const stringA = String(valueA).toLowerCase();
		const stringB = String(valueB).toLowerCase();

		if (stringA < stringB) {
			return sorting.direction === 'asc' ? -1 : 1;
		}
		if (stringA > stringB) {
			return sorting.direction === 'asc' ? 1 : -1;
		}

		return 0;
	};

// This export is being duplicated in the __playwright__/page-objects/ConfluenceAnalytics.ts file as it
// cannot be imported; if changed, update there as well
export enum AnalyticsTableUIState {
	Error = 'Error',
	Loading = 'Loading',
	Filtering = 'Filtering',
	NoFilterResults = 'NoFilterResults',
	Data = 'Data',
	Empty = 'Empty',
}

export const getTableUIState = <TableDataModel extends object>(
	props: { hasError?: boolean; isLoading?: boolean; isFiltering?: boolean },
	table: { rows: CharlieTableRow<TableDataModel>[] },
): AnalyticsTableUIState => {
	if (Boolean(props.hasError)) {
		return AnalyticsTableUIState.Error;
	}

	if (Boolean(props.isLoading)) {
		return AnalyticsTableUIState.Loading;
	}

	if (Boolean(props.isFiltering) && table.rows.length === 0) {
		return AnalyticsTableUIState.NoFilterResults;
	}

	if (Boolean(props.isFiltering)) {
		return AnalyticsTableUIState.Filtering;
	}

	if (table.rows.length === 0) {
		return AnalyticsTableUIState.Empty;
	}

	return AnalyticsTableUIState.Data;
};
